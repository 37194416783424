import React from "react";
import styled from "styled-components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { COLORS, RADIUS, TEXTSIZE } from "../../theme/constants";
import { useNavigate } from "react-router-dom";
import { ReactComponent as RightArrow } from "../../assets/svgs/arrow_right.svg";

const Wrapper = styled.div`
  padding-top: 36px;

  .section {
    margin-bottom: 60px;
  }

  .section__title {
    font-size: ${TEXTSIZE.xxLarge};
    font-weight: 700;
    margin-bottom: 20px;
  }

  .status {
    color: ${COLORS.gray_7};
    font-weight: 600;

    &.done {
      color: var(--notification_successLight);
    }
  }

  .card {
    background-color: var(--bglightColor);
    font-size: ${TEXTSIZE.medium};
    border-radius: ${RADIUS.pc};
    box-shadow: none;

    &__title {
      font-weight: 600;
      margin-bottom: 10px;
      color: var(--textColor);
    }

    &__text {
      font-weight: 400;
      color: ${COLORS.gray_7};

      &--sub {
        font-size: ${TEXTSIZE.small};
      }
    }
  }

  .step--list {
    display: flex;
  }

  .step--item {
    flex: 1 1 33%;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 16px auto;
      padding: 1rem;
      background: ${COLORS.grey50};
      color: ${COLORS.grey600};
      border-radius: ${RADIUS.xxxLarge};
      font-size: ${TEXTSIZE.large};
      font-weight: 600;
      cursor: pointer;

      &:hover {
        background: ${COLORS.primary4};
        color: ${COLORS.white};
      }

      &.disabled {
        background: ${COLORS.grey100};
        color: ${COLORS.grey600};
        cursor: not-allowed;
      }
    }

    &:last-child {
      .card {
        width: 100%;
      }
    }
  }

  .step__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: ${TEXTSIZE.large};
    font-weight: 700;
    margin-bottom: 16px;

    .step__line {
      width: 28px;
      border-bottom: 1px dotted var(--subTextColor);

      &.last {
        position: relative;
        margin-right: 20px;

        .icon {
          position: absolute;
          right: -15px;
          top: 50%;
          transform: translateY(-50%);
          fill: var(--subTextColor);
        }
      }
    }

    .step__description {
      font-size: ${TEXTSIZE.small};
      color: ${COLORS.gray_7};
      font-weight: 400;
    }
  }

  .card--step {
    width: 95%;
    height: 259px;

    .card__title {
      font-size: ${TEXTSIZE.large};
      font-weight: 600;
      margin-bottom: 16px;
    }

    .card__text {
      li {
        line-height: calc(${TEXTSIZE.medium} + 10px);
        margin-bottom: 12px;
      }
    }
  }
`;

function CalculateComponent() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <section className="section">
        <h2 className="section__title">마이 PIC 출금</h2>
        <Card className="card">
          <CardContent className="card__content">
            <p className="card__title">정산 신청 가능일</p>
            <p className="card__text">신청하는 달의 25일까지</p>
          </CardContent>
          <CardContent className="card__content">
            <p className="card__title">정산 승인 후 정산일</p>
            <p className="card__text">
              신청 완료 후 익월 10일까지{" "}
              <span className="card__text--sub">
                (ex: 3월에 정산 신청을 원하는 경우, 3월 25일까지 신청 완료 후
                4월 10일에 정산 완료)
              </span>
            </p>
          </CardContent>
        </Card>
      </section>

      <section className="section">
        <h2 className="section__title">D'/ICON의 정산 진행을 알려드릴게요.</h2>
        <ul className="step--list">
          <li className="step--item">
            <div className="step__title">
              <div className="step__text">정산 등록</div>
              <div className="step__line"></div>
              <span className="step__description">영업일 기준 2-3일 소요</span>
              <div className="step__line last">
                <RightArrow className="icon" />
              </div>
            </div>
            <Card className="card card--step">
              <CardContent className="card--step__content">
                <p className="card__title">정산 등록 시 필요 서류</p>
                <ul className="card__text">
                  <li>본인 명의의 계좌</li>
                  <li>사업자 경우, 사업자 등록증과 통장사본</li>
                  <li>외국인의 경우, 외국인 인증 서류</li>
                </ul>
              </CardContent>
            </Card>
            <button
              type="button"
              className="button"
              onClick={() => navigate("/signup-account")}
            >
              정산 등록하기
            </button>
          </li>
          <li className="step--item">
            <div className="step__title">
              <div className="step__text">정산 신청</div>
              <div className="step__line"></div>
              <span className="step__description">영업일 기준 2-3일 소요</span>
              <div className="step__line last">
                <RightArrow className="icon" />
              </div>
            </div>
            <Card className="card card--step">
              <CardContent className="card--step__content">
                <p className="card__title">정산 신청 과정</p>
                <ul className="card__text">
                  <li>정산 가능한 pic 금액 확인 후, 입력하세요.</li>
                  <li>
                    본인 인증을 받은 이름과 입금 받으실 계좌의 예금주 명이
                    일치하는지 확인해주세요. 
                  </li>
                  <li>정산 신청서는 매 월 25일까지 신청해주세요.</li>
                </ul>
              </CardContent>
            </Card>
            <button
              type="button"
              className={`button ${false ? "disabled" : null}`}
              disabled={false}
              onClick={() => navigate("/claim")}
            >
              정산 신청하기
            </button>
          </li>
          <li className="step--item">
            <div className="step__title">
              <div className="step__text">정산 승인</div>
              {true ? <span className="status ">진행중</span> : null}
            </div>
            <Card className="card card--step">
              <CardContent className="card--step__content">
                <p className="card__title">정산 승인 과정</p>
                <ul className="card__text">
                  <li>
                    모든 절차를 진행 후, 심사가 완료되면 익월  10일까지 정산이
                    완료됩니다. 
                  </li>
                  <li>정산 시, 정산 내역서는 가입 이메일을 통해 발송됩니다.</li>
                </ul>
              </CardContent>
            </Card>
          </li>
        </ul>
      </section>
    </Wrapper>
  );
}

export default React.memo(CalculateComponent);
