import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS, RADIUS, TEXTSIZE } from "../theme/constants";
import { ReactComponent as PicIcon } from "../assets/svgs/piced.svg";
import { ReactComponent as ArrowIcon } from "../assets/svgs/arrow_bottom.svg";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DrawerPortal from "../containers/drawers/DrawerPortal";
import DrawerFrame from "../containers/drawers/DrawerFrame";
import ChargeSkeleton from "./layouts/skeleton/Charge";
import ModalPortal from "./modal/ModalPortal";
import ModalFrame from "./modal/ModalFrame";
import { setChargethePic } from "../api";
import { toast } from "react-toastify";

const Wrapper = styled.div`
  .charge-button-temporary {
    width: 120px;
    height: 50px;
    color: ${COLORS.white};
    font-weight: 700;
    font-size: ${TEXTSIZE.medium};
    border-radius: ${RADIUS.medium};
    background-color: ${COLORS.mainColor};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .mypic {
    display: flex;
    align-items: center;
    height: 56px;
    font-weight: 700;
    font-size: 18px;
    padding: 0 16px;
    width: calc(100% + 32px);
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
    position: relative;

    .pic-wrap {
      display: flex;
      align-items: center;
      height: 100%;
      gap: 8px;
      margin-right: 8px;

      .pic-icon {
        fill: var(--mainColor);
      }
    }
    .pic-count {
      display: flex;
      align-items: center;
      height: 56px;
    }
  }

  .section {
    margin-top: 24px;

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      font-size: ${TEXTSIZE.large};
      margin-bottom: 8px;
    }
  }

  .charge-list {
    .charge-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;
      background-color: var(--colorF3to3);
      border-radius: ${RADIUS.medium};
      padding: 12px;
      font-size: ${TEXTSIZE.medium};
      font-weight: 600;

      .pic-price {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
      }

      .button-charge {
        width: 45px;
        height: 28px;
        background-color: ${COLORS.bgBlack};
        color: ${COLORS.white};
        border-radius: ${RADIUS.medium};
        font-size: ${TEXTSIZE.small};
        font-weight: 600;
        cursor: pointer;
      }
    }
  }

  .details {
    margin-bottom: 80px;

    .details-title {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;

      &::-webkit-details-marker {
        display: none;
      }
    }
    .summary-icon {
      transition: all 0.2s ease-in;
    }

    &[open] {
      .summary-icon {
        transform: rotate(180deg);
      }
    }

    .summary-info {
      padding: 12px;
      background-color: var(--colorF3to3);
      color: ${COLORS.gray_7};
      border-radius: ${RADIUS.medium};
      font-size: ${TEXTSIZE.xSmall};
      line-height: 17px;
    }
  }

  .charge-info {
    margin: 12px auto 60px;
    padding-top: 12px;
    border-top: 1px solid var(--colorF3to3);
    color: ${COLORS.gray_7};
    font-size: ${TEXTSIZE.xSmall};
    line-height: 17px;
  }
`;

function Charge({ data }) {
  const isLoading = false;
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [price, setPrice] = useState(0);
  const [pic, setPic] = useState(0);
  const [userPic, setUserPic] = useState(data);
  const { IMP } = window;

  const handleDrawer = (value) => {
    const drawerRoot = document.querySelector("#drawer-root");
    drawerRoot.style.display = value ? "block" : "none";
    setDrawerOpen(value);
  };

  const handleModal = () => {
    const modalRoot = document.querySelector("#modal-root");
    modalRoot.style.display = "block";
    setModalOpen(true);
  };

  useEffect(() => {
    impInit();
  }, []);

  const impInit = () => {
    IMP.init(process.env.REACT_APP_PORTONE_CODE);
  };

  const handlePayment = (amount, pic) => {
    setPrice(amount);
    setPic(pic);
    // handleModal();
    IMP.request_pay(
      {
        pg: `ksnet.${process.env.REACT_APP_PORTONE_KSNET_MID}`,
        pay_method: "card",
        merchant_uid: `mid_${new Date().getTime()}`,
        name: `${pic} PIC`,
        amount: Number(amount),
        // buyer_email: "gildong@gmail.com",
        buyer_name: "홍길동",
        // buyer_tel: "010-4242-4242",
        // buyer_addr: "서울특별시 강남구 신사동",
        // buyer_postcode: "01181",
      },
      (res) => {
        // callback
        if (res.success) {
          alert("결제 성공");
        } else {
          alert(`결제 실패: ${res.error_msg}`);
        }
      }
    );
  };

  const handleChargeTemporary = () => {
    setChargethePic(1000)
      .then((res) => {
        if (res.data.result) {
          const user = JSON.parse(window.localStorage.getItem("user"));
          user.user_funds.charge = res.data.data.charge;
          window.localStorage.setItem("user", JSON.stringify(user));
          setUserPic(res.data.data.charge);
          toast("충전이 완료되었습니다");
          navigate(-1);
        }
      })
      .catch((error) => {
        console.log(error);
        toast(error.response.data.message);
      });
  };

  return (
    <Wrapper>
      {isLoading ? (
        <ChargeSkeleton />
      ) : (
        <>
          <div className="section section-title">
            <h3>현재 마이PIC</h3>
          </div>
          <div className="mypic">
            <div className="pic-wrap">
              <PicIcon className="pic-icon" stroke={COLORS.mainColor} />
            </div>
            <div className="pic-count">
              {userPic.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}개
            </div>
          </div>
          <div className="section section-title">
            <h3>PIC 임시 충전하기</h3>
          </div>
          <button
            type="button"
            className="charge-button-temporary"
            onClick={handleChargeTemporary}
          >
            임시 충전하기
          </button>
          <div className="section">
            <div className="section-title">
              <h3>PIC 충전</h3>
            </div>
            {modalOpen && (
              <ModalPortal>
                <ModalFrame
                  modalType="charge"
                  data={{ amount: price, pic: pic }}
                  setOnModal={setModalOpen}
                ></ModalFrame>
              </ModalPortal>
            )}
            <List className="charge-list">
              <ListItem disablePadding className="charge-item-wrap">
                <ListItemText
                  primary={
                    <div className="charge-item">
                      <div className="pic-count">1,000개</div>
                      <div className="pic-price">
                        <div className="pic-text">10,000원</div>
                        <button
                          type="button"
                          className="button-charge"
                          onClick={() => handlePayment(10000, 1000)}
                        >
                          결제
                        </button>
                      </div>
                    </div>
                  }
                />
              </ListItem>
              <ListItem disablePadding className="charge-item-wrap">
                <ListItemText
                  primary={
                    <div className="charge-item">
                      <div className="pic-count">2,000개</div>
                      <div className="pic-price">
                        <div className="pic-text">20,000원</div>
                        <button
                          type="button"
                          className="button-charge"
                          onClick={() => handlePayment(20000, 2000)}
                        >
                          결제
                        </button>
                      </div>
                    </div>
                  }
                />
              </ListItem>
              <ListItem disablePadding className="charge-item-wrap">
                <ListItemText
                  primary={
                    <div className="charge-item">
                      <div className="pic-count">3,000개</div>
                      <div className="pic-price">
                        <div className="pic-text">30,000원</div>
                        <button
                          type="button"
                          className="button-charge"
                          onClick={() => handlePayment(30000, 3000)}
                        >
                          결제
                        </button>
                      </div>
                    </div>
                  }
                />
              </ListItem>
              <ListItem disablePadding className="charge-item-wrap">
                <ListItemText
                  primary={
                    <div className="charge-item">
                      <div className="pic-count">10,000개</div>
                      <div className="pic-price">
                        <div className="pic-text">100,000원</div>
                        <button
                          type="button"
                          className="button-charge"
                          onClick={() => handlePayment(100000, 10000)}
                        >
                          결제
                        </button>
                      </div>
                    </div>
                  }
                />
              </ListItem>
            </List>
            {drawerOpen && (
              <DrawerPortal>
                <DrawerFrame
                  setOnDrawer={() => handleDrawer(false)}
                  type="user"
                />
              </DrawerPortal>
            )}
          </div>

          <details className="section details">
            <summary className="details-title">
              PIC 환불 규정
              <div className="summary-icon">
                <ArrowIcon className="icon-fill__color1CtoF" />
              </div>
            </summary>
            <p className="summary-info">
              - 환불이란 소진된 유료 PIC을 결제한 수단 또는 현금으로 회원에게
              다시 돌려주는 것을 뜻합니다. 단, 카드 결제 건에 대한 환불은 카드
              매출 취소로만 가능하며, 현금으로 환불처리는 불가능합니다.
              <br />
              - 무료 PIC은 환불의 범위에서 제외됩니다.
              <br />
              - PIC의 환불은 구매한 PIC 중 일부가 사용되지 않은 상태에서,
              구매로부터 7일 이내에 요청한 경우에 가능하며, 일부가 사용되었거나
              7일이 지난 경우에는 환불이 불가능합니다.
              <br />
              - 주민등록법, 저작권법, 컴퓨터프로그램보호법, 정보통신망법 등 각종
              법령에 대한 중대한 불법행위 또는 약관 내 명시된 내용과 같이
              D'/ICON 운영에 심각한 악영향을 미치는 행위를 한 것으로 판단되는
              이용자의 계정 및 아이디 이용을 제한하거나 해당 이용자와의 계약을
              해지하는 경우, D'/ICON는 이용료를 환불하지 않을 수 있습니다. 다만,
              회원님이 중대한 불법행위 또는 D'/ICON 운영에 심각한 영향을 미치는
              행위를 하지 않았다는 것을 소명하는 경우에는 그러하지 않습니다.
              <br />
              D'/ICON는 회원님이 충전한 PIC을 사용하지 않았고, 결제대행사를 통해
              전액 취소 처리가 가능한 경우, 즉각 취소 처리를 진행합니다. 그렇지
              않은 경우에는 회원님이 환불 요청 시 기입한 은행 계좌를 통해
              송금하며, 경우에 따라 은행 송금 수수료가 발생하는 경우, 이를
              제외한 금액으로 입금 처리합니다.
              <br />
              - 상기 항목에 따라 환불이 가능한 경우에도 회원님이 결제 시 이용한
              특정 결제 수단을 제공하는 결제대행사 혹은 금융사의 요청이 있을
              경우, 환불이 제한되거나 보류될 수 있습니다. 이에 해당하는 결제
              수단의 경우, 별도 안내를 통해 확인할 수 있으며, D'/ICON는 특정
              결제 수단에 대한 결제대행사 혹은 금융사의 정책 변경이 있을 경우
              즉각 고지, 안내합니다.
              <br />
              - 다음의 각 항에 해당하는 경우, 환불 적용이 불가합니다.
              <br />
              : 콘텐츠에 대한 불만
              <br />
              구매 실수로 인한 환불 요청
            </p>
          </details>
        </>
      )}
    </Wrapper>
  );
}

export default React.memo(Charge);
