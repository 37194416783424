import { createContext, useContext, useRef, useState } from "react";
import { Outlet, useLocation, useMatch, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Box, InputAdornment, TextField } from "@mui/material";
import {
  COLORS,
  HEADER_LEFT_TYPE,
  HEADER_CENTER_TYPE,
  WIDTH,
} from "@theme/constants";
import Navigation from "@containers/navigations/Navigation";
import { useElementSize } from "@hooks";
import { GlobalStateContext } from "../../context/GlobalStateProvider";
import { useAuth } from "../../context/Auth";
import NavigationTablet from "../../containers/navigations/NavigationTablet";
import NavigateDesktop from "../../containers/navigations/NavigateDesktop";
import BaseFooterNew from "../../containers/footers/BaseFooterNew";
import CommonHeader from "./header/CommonHeader";
import BaseButtonScrollTop from "../buttonScrollTop/BaseButtonScrollTop";
import BaseButtonScrollTopNew from "../buttonScrollTop/BaseButtonScrollTopNew";
import { RADIUS, SORT_BY, TEXTSIZE } from "../../theme/constants";
import { ReactComponent as LeftArrow } from "@icons/arrow_left.svg";
import { ReactComponent as LogoIcon } from "@icons/logo-light.svg";
import Logo from "@icons/logo.png";

import { ReactComponent as SearchIcon } from "@icons/search.svg";
import { ReactComponent as ClearIcon } from "@icons/clear.svg";
import styled from "styled-components";

const Header = styled.header`
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 1rem;
`;

function SearchFeedLayout(props) {
  const {
    // isNavigation = false,
    // isSideBar = false,
    isFooter = false,
    isHeader = true,
    isScrollTop = true,
    isGuideline = true,
    leftType = "",
    centerType = "",
    rightType = "",
    pageTitle = "",
    titleStyle = {},
    isSearchInput = false,
  } = props;

  const isNavigation = true;
  const isSideBar = true;
  const { globalState } = useContext(GlobalStateContext);

  const navigate = useNavigate();
  const location = useLocation();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const [inputChange, setInputChange] = useState(false);

  const contentRef = useRef(null);
  const navRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);

  const isMobileWithoutFold = useMediaQuery({
    query: `(max-width: ${WIDTH.fold_from})`,
  });

  // 516-674
  const isFold = useMediaQuery({ minWidth: 516, maxWidth: 674 });

  const isShowNavigateTablet = useMediaQuery({ minWidth: 516, maxWidth: 1200 });

  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });

  const [enterPress, setEnterPress] = useState(0);

  const [searchParams, setSearchParams] = useState({
    keyword: "",
    sort_by: SORT_BY.LATEST,
    offset: 0,
    limit: 16,
  });

  const [selectedPost, setSelectedPost] = useState({
    keyword: "",
    sort_by: SORT_BY.LATEST,
    id: "",
    index: 0,
  });

  const handleOnKeyDown = (e) => {
    if (e.key === "Enter") {
      setEnterPress(enterPress + 1);
    }
  };

  const handleClear = () => {
    setSearchKeyword("");
    setEnterPress(enterPress + 1);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const onChangeSearchInput = (event) => {
    setSearchKeyword(event.target.value);
    setInputChange(!inputChange);
  };

  const onClickLogo = () => {
    navigate("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const styleContentByNavbar = (left) => {
    const styleLeft = {
      pl:
        isMobileWithoutFold || !isSideBar
          ? "0px"
          : isShowNavigateTablet && isNavigation
          ? "80px"
          : isPc
          ? "300px"
          : 0,
    };

    const styleRight = {
      pr: isShowNavigateTablet && isNavigation ? "80px" : 0,
      pl: isPc ? "300px" : 0,
    };

    return !left ? styleRight : styleLeft;
  };

  return (
    <SearchContext.Provider
      value={{
        isSearched,
        enterPress,
        searchKeyword,
        setSearchKeyword,
        inputChange,
        searchParams,
        setSearchParams,
        selectedPost,
        setSelectedPost,
      }}
    >
      {isNavigation && isShowNavigateTablet && (
        <NavigationTablet ref={navRef} />
      )}
      {!isPc && isNavigation && isMobileWithoutFold && (
        <Navigation ref={navRef} />
      )}
      <Box
        sx={{
          maxWidth: "1200px",
          mx: "auto",
        }}
        id={"scrollableContent"}
        // onScroll={handleScroll}
        // ref={containerRef}
      >
        {/* header */}
        {isHeader && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              width: "100%",
              zIndex: 100,
              maxWidth: "1200px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              {isPc && (
                <Box
                  sx={{
                    flex: "0 1 300px",
                    display: "flex",
                    alignItems: "center",
                    pl: "40px",
                    cursor: "pointer",
                  }}
                  onClick={onClickLogo}
                >
                  <img src={Logo} />
                </Box>
              )}
              <Box
                sx={{
                  flex: "1 1 auto",
                  height: "fit-content",
                  background: "white",
                }}
              >
                <Header>
                  <Box onClick={handleGoBack} sx={{ cursor: "pointer" }}>
                    <LeftArrow fill={COLORS.grey600} />
                  </Box>
                  <Box sx={{ flex: "1 1 auto" }}>
                    {isSearchInput && (
                      <TextField
                        autoFocus
                        placeholder={
                          location.pathname === "/explore/user"
                            ? "검색어를 입력해주세요."
                            : ""
                        }
                        value={searchKeyword}
                        onChange={onChangeSearchInput}
                        onKeyDown={handleOnKeyDown}
                        sx={{
                          width: "100%",
                          height: "40px",
                          borderRadius: RADIUS.large,
                          "& .MuiInputBase-root": {
                            height: "100%",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: RADIUS.xxLarge,
                          },
                          mr: 2,
                          ml: 1,
                        }}
                        inputProps={{
                          style: {
                            height: "40px",
                            padding: "0.3rem 0.2rem 0.2rem 0.3rem",
                            zIndex: 1,
                            color: COLORS.black1,
                            fontSize: TEXTSIZE.medium,
                          },
                          sx: {
                            "&::placeholder": {},
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{ zIndex: 1, marginLeft: "-1px" }}
                            >
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          endAdornment: searchKeyword.length > 0 && (
                            <InputAdornment
                              position="end"
                              sx={{ zIndex: 1, cursor: "pointer" }}
                              onClick={handleClear}
                            >
                              <ClearIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </Box>
                </Header>
              </Box>
            </Box>
          </Box>
        )}
        {/* sidebar */}
        {isSideBar && (
          <Box
            sx={{
              position: "fixed",
              top: "56px",
              width: "100%",
              zIndex: 100,
              maxWidth: "300px",
            }}
          >
            <NavigateDesktop isGuideline={isGuideline} />
          </Box>
        )}
        {/* content */}
        <Box
          sx={{
            width: "100%",
            maxWidth: "1200px",
            mx: isPc ? "0" : "auto",
            pt: isHeader ? "56px" : 0,
            pb: !isHeader ? 0 : isPc ? "180px" : "100px",
            ...styleContentByNavbar(globalState?.isNavbarLeft),
          }}
          ref={contentRef}
          id="main-content"
        >
          <Outlet />
        </Box>
        {isScrollTop && (
          <BaseButtonScrollTopNew
            styleButtonProps={{}}
            isDarkMode={false}
            isScrolling={isScrolling}
            isSideBar={isSideBar}
            isFooter={isFooter}
          />
        )}
      </Box>
    </SearchContext.Provider>
  );
}
export const SearchContext = createContext();
export default SearchFeedLayout;
