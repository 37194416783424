import React from "react";
import styled from "styled-components";
import "react-bubble-ui/dist/index.css";
import {
  COLORS,
  FONT_WEIGHT,
  LINE_HEIGHT,
  TEXTSIZE,
  WIDTH,
} from "../theme/constants";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import NoData from "../components/NoData";
import { textMediumMuiStyle, truncateStyle } from "../utils/commonStyle";
import checkBoxImage from "../assets/images/checkBox.svg";
import checkedBoxImage from "../assets/images/checkedBox.svg";
import { buttonLargeMuiStyle } from "../utils/commonStyle";
import CircularComponent from "../containers/process/CircularComponent";
import useGetSuggestConnection from "../hooks/useGetSuggestConnection";
import ImageAvatar from "../assets/images/avatar.png";
import useCreateConnects from "../hooks/useCreateConnects";

const Wrapper = styled.div`
  position: relative;
  // height: calc(100% - 58px)
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }
`;

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 230px);
  position: relative;
`;

function SuggestConnect() {
  const { data, isLoading } = useGetSuggestConnection();
  const { handleConnects } = useCreateConnects();

  const handleSubmit = () => {
    // console.log("handleSubmit", data);
    const requests = [];
    data.forEach((user) => {
      requests.push(user?.user_id);
    });
    handleConnects({ kol: requests });
  };

  if (isLoading)
    return (
      <HeightWhenEmpty>
        <CircularComponent />
      </HeightWhenEmpty>
    );

  return (
    <>
      <Wrapper>
        <Box
          sx={{
            flex: "1 1 auto",
          }}
        >
          <Box sx={{ my: 1 }}>
            <Typography
              sx={{
                fontSize: TEXTSIZE.xLarge,
                fontWeight: FONT_WEIGHT.xxLarge,
                lineHeight: LINE_HEIGHT.xxxLarge,
                color: COLORS.white,
                whiteSpace: "pre-line",
              }}
            >
              {`안녕하세요.\nD'/ICON에 오신 것을 환영합니다!`}
            </Typography>
            <Typography
              sx={{
                ...textMediumMuiStyle,
                fontSize: TEXTSIZE.small,
                color: COLORS.grey200,
                whiteSpace: "pre-line",
                mt: 1,
              }}
            >
              {`특별한 콘텐츠로 소통할 수 있는 D'/ICON의 크리에이터를\n먼저 만나보세요!`}
            </Typography>
          </Box>
          <Box sx={{ mt: "28px" }}>
            {data?.length > 0 ? (
              <>
                <FormGroup>
                  <Grid container spacing={2}>
                    {data?.map((user, index) => (
                      <Grid
                        item
                        xs={4}
                        key={index}
                        sx={{ textAlign: "center" }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked
                              sx={{ display: "none" }}
                              icon={<img src={checkBoxImage} />}
                              checkedIcon={<img src={checkedBoxImage} />}
                            />
                          }
                          labelPlacement="bottom"
                          label={
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "80px",
                                  height: "80px",
                                  backgroundImage:
                                    "linear-gradient(to right bottom, #DC3FF0, #FFA847)",
                                  padding: "3px",
                                  borderRadius: "50%",
                                  mb: 0.5,
                                }}
                              >
                                <Avatar
                                  src={user?.profile_image_url || ImageAvatar}
                                  sx={{
                                    height: "100%",
                                    width: "100%",
                                  }}
                                />
                              </Box>
                              <Typography
                                sx={{
                                  ...textMediumMuiStyle,
                                  ...truncateStyle,
                                  width: "90px",
                                  fontWeight: FONT_WEIGHT.xxLarge,
                                  color: COLORS.white,
                                }}
                              >
                                {user?.nickname}
                              </Typography>
                              <Typography
                                sx={{
                                  ...textMediumMuiStyle,
                                  ...truncateStyle,
                                  width: "90px",
                                  fontSize: TEXTSIZE.small,
                                  color: COLORS.grey200,
                                }}
                              >
                                {user?.introduce}
                              </Typography>
                            </Box>
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </>
            ) : (
              <NoData type="noconnect" />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            // position: "fixed",
            width: "100%",
            // maxWidth: "600px",
            // bottom: "0px",
            // left: 0,
            // width: "calc(100% - 32px)",
            // maxWidth: "calc(100% - 32px)",
          }}
        >
          <Button
            sx={{
              ...buttonLargeMuiStyle,
              backgroundColor: COLORS.white,
              color: COLORS.black1,
              "&:hover": {
                backgroundColor: COLORS.white,
                color: COLORS.black1,
              },
              padding: "0.75rem",
            }}
            variant="contained"
            size="large"
            onClick={() => handleSubmit()}
          >
            다음
          </Button>
        </Box>
      </Wrapper>
    </>
  );
}

export default React.memo(SuggestConnect);
