import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import {
  COLORS,
  FONT_WEIGHT,
  LINE_HEIGHT,
  RADIUS,
  TEXTSIZE,
  WIDTH,
} from "../../theme/constants";
import { useNavigate } from "react-router-dom";
import { deleteAccount } from "@api";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Typography,
} from "@mui/material";
import {
  buttonLargeMuiStyle,
  inputMuiStyle,
  textMediumMuiStyle,
  textLargeMuiStyle,
} from "../../utils/commonStyle";
import { handleError, numberWithCommas } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import checkBoxImage from "@images/checkBox.svg";
import checkedBoxImage from "@images/checkedBox.svg";
import ModalComponent from "../../components/modal/ModalComponent";
import { useModalReducer } from "@hooks";
import { MODAL_STATUS } from "../../hooks/useModalReducer";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }
`;

const labelStyle = {
  fontSize: TEXTSIZE.large,
  fontWeight: FONT_WEIGHT.xxLarge,
  lineHeight: LINE_HEIGHT.xxxxLarge,
  color: COLORS.black1,
  mb: 1,
};

function DeleteAccount() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [confirm, setConfirm] = useState(false);
  const { modalData, dispatchModal } = useModalReducer();
  // const [modalData, setModalData] = useState({
  //   open: false,
  //   btnOKText: "",
  //   btnCancelText: "",
  //   title: "",
  //   subTitle: "",
  //   content: "",
  // });

  const onClickCheckbox = (event) => {
    setConfirm(event.target.checked);
  };

  const handleChange = (event) => {
    setValue(event?.target?.value);
  };

  const handleSubmit = async () => {
    try {
      const requestData = {
        reason: value,
      };
      await deleteAccount(requestData);

      dispatchModal({
        type: MODAL_STATUS.OPEN,
        data: {
          btnOKText: t("button.check"),
          btnCancelText: "",
          title: t("dialog.title.delete_account"),
          subTitle: "",
          content: (
            <Typography
              sx={{
                ...textMediumMuiStyle,
                color: COLORS.grey900,
                lineHeight: LINE_HEIGHT.xxxLarge,
                whiteSpace: "pre-line",
              }}
            >
              {t("dialog.content.delete_account")}
            </Typography>
          ),
        },
      });

      // setModalData({
      //   open: true,
      //   btnOKText: "확인",
      //   btnCancelText: "",
      //   title: "D'/ICON 회원 탈퇴가 완료되었습니다.",
      //   subTitle: "",
      //   content: (
      //     <Typography
      //       sx={{
      //         ...textMediumMuiStyle,
      //         color: COLORS.grey900,
      //         lineHeight: LINE_HEIGHT.xxxLarge,
      //         whiteSpace: "pre-line",
      //       }}
      //     >
      //       그동안 D'/ICON를 이용해 주셔서 감사합니다.
      //     </Typography>
      //   ),
      // });
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  //modal action
  const handleOk = () => {
    dispatchModal({ type: MODAL_STATUS.CLOSE });
    logout();
  };

  const handleClose = () => {
    dispatchModal({ type: MODAL_STATUS.CLOSE });
  };

  return (
    <Wrapper>
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => handleClose()}
        handleOk={() => handleOk()}
        handleCancel={() => handleClose()}
        btnOKProps={{}}
        btnCancelProps={{}}
      />

      <Box>
        <InputLabel sx={labelStyle}>
          D'/ICON를 떠나는 이유를 적어주세요.
        </InputLabel>
        <TextField
          name={"reason"}
          value={value}
          multiline
          rows={12}
          helperText={
            value && value?.length < 10 ? "최소 10글자 이상 입력해 주세요." : ""
          }
          FormHelperTextProps={{
            style: {
              color: COLORS.redError,
            },
          }}
          onChange={handleChange}
          placeholder={"D'/ICON의 어떤 점이 불편하셨나요?"}
          sx={{
            width: "100%",
            borderRadius: RADIUS.large,
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: RADIUS.xxLarge,
              backgroundColor: COLORS.grey50,
              border: "none",
            },
          }}
          inputProps={{
            sx: { ...inputMuiStyle, borderRadius: 0, px: 0 },
            // maxLength: maxLength,
          }}
        />
      </Box>
      <Box sx={{ my: 4 }}>
        <InputLabel sx={{ ...labelStyle, mb: 2 }}>
          탈퇴 전에 이건 꼭 아셔야 해요.
        </InputLabel>
        <Box
          sx={{
            display: "flex",
            backgroundColor: COLORS.grey25,
            borderRadius: RADIUS.xLarge,
            padding: "0.75rem 1.25rem",
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mr: 1.5 }}>
            <Typography
              sx={{
                color: COLORS.black1,
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "20px",
              }}
            >
              {numberWithCommas(1000)}
            </Typography>
            <Typography sx={textLargeMuiStyle}>PIC</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "-webkit-fill-available",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: COLORS.black1,
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "20px",
              }}
            >
              {numberWithCommas(10000)}
            </Typography>
            <Typography sx={textLargeMuiStyle}>원</Typography>
          </Box>
        </Box>
        <Typography
          sx={{
            ...textMediumMuiStyle,
            fontSize: TEXTSIZE.small,
            color: COLORS.grey600,
            whiteSpace: "pre-line",
          }}
        >
          {`회원을 탈퇴하시면, 보유하고 계신 PIC이 모두 소멸되고, 복원할 수
          없습니다. (5만PIC 이상부터는 출금이 가능합니다.)
          회원 탈퇴 후에는 회원 정보와 그 동안의 추억이 담긴 콘텐츠가 삭제되어 복원이 불가능합니다.
          (단, 유료 콘텐츠는 삭제되지 않습니다.)`}
        </Typography>
      </Box>

      <FormGroup>
        <FormControlLabel
          required
          control={
            <Checkbox
              onChange={onClickCheckbox}
              checked={confirm}
              icon={<img src={checkBoxImage} />}
              checkedIcon={<img src={checkedBoxImage} />}
            />
          }
          label="탈퇴 시 내가 보유한 PIC을 포기합니다. (필수)"
        />
      </FormGroup>

      <Button
        sx={{ ...buttonLargeMuiStyle, mt: 4 }}
        disabled={!confirm || value.length < 10}
        variant="contained"
        size="large"
        onClick={handleSubmit}
      >
        확인
      </Button>
    </Wrapper>
  );
}

export default React.memo(DeleteAccount);
