import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS, RADIUS, TEXTSIZE } from "../../theme/constants";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  padding-top: 60px;

  .step__name {
    font-size: 28px;
    font-weight: 700;
  }

  .step__description {
    font-size: ${TEXTSIZE.medium};
    font-weight: 400;
    color: ${COLORS.gray_7};
    margin: 16px 0 60px;
  }

  .step__title {
    font-size: ${TEXTSIZE.xxLarge};
    font-weight: 700;
    margin-bottom: 12px;
  }

  .text--title {
    .MuiTypography-root {
      font-size: ${TEXTSIZE.xxLarge};
      font-weight: 700;
    }
  }

  .row-wrap {
    font-size: ${TEXTSIZE.large};
    font-weight: 700;
  }

  .MuiButtonBase-root {
    &.Mui-checked {
      color: var(--textColor);
    }
  }

  .MuiSvgIcon-root {
    fill: var(--textColor);
  }

  .terms {
    margin-bottom: 40px;
  }

  .term__content {
    margin: 12px auto 40px;
    padding: 12px;
    width: 100%;
    height: 104px;
    overflow-y: auto;
    border-radius: ${RADIUS.medium};
    background: var(--bglightColor);
    font-size: ${TEXTSIZE.small};
    line-height: ${TEXTSIZE.xxLarge};
  }
`;

function Step2({ data, handleChange }) {
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState(data.terms);

  useEffect(() => {
    handleChange({ terms: checked });
  }, [checked]);

  const handleChecked = (eventIndex) => {
    setChecked(
      checked.map((item, index) => {
        return index === eventIndex ? !item : item;
      })
    );
  };

  const handleChangeAll = () => {
    if (!checked.includes(false)) {
      setChecked([false, false, false]);
    } else {
      setChecked([true, true, true]);
    }
  };

  const termsList = [
    {
      text: "D'/ICON 서비스 이용 약관 (필수)",
      require: true,
      terms: "term1",
      content:
        "몸이 못하다 소금이라 이상 부패를 때에, 원대하고, 봄바람이다. 열락의 꽃이 끓는 지혜는 길지 풀이 인생의 아니다. 이성은 살았으며, 우는 반짝이는 없으면 눈에 가슴이 우리 듣는다. 구하지 이것이야말로 노년에게서 가슴이 행복스럽고 되려니와, 이는 붙잡아 청춘 피다. 소금이라 무엇을 같은 온갖 것은 현저하게 거선의 보라. 없으면 싶이 인생을 실현에 철환하였는가? 청춘이 바로 목숨을 트고, 운다. 보배를 하는 찾아 보이는 생생하며, 무엇이 보내는 얼음이 예가 보라. 가치를 보이는 이것이야말로 끓는다.",
    },
    {
      text: "D'/ICON 서비스 이용에 관한 고지 및 동의 (필수)",
      require: true,
      terms: "term2",
      content:
        "몸이 못하다 소금이라 이상 부패를 때에, 원대하고, 봄바람이다. 열락의 꽃이 끓는 지혜는 길지 풀이 인생의 아니다. 이성은 살았으며, 우는 반짝이는 없으면 눈에 가슴이 우리 듣는다. 구하지 이것이야말로 노년에게서 가슴이 행복스럽고 되려니와, 이는 붙잡아 청춘 피다. 소금이라 무엇을 같은 온갖 것은 현저하게 거선의 보라. 없으면 싶이 인생을 실현에 철환하였는가? 청춘이 바로 목숨을 트고, 운다. 보배를 하는 찾아 보이는 생생하며, 무엇이 보내는 얼음이 예가 보라. 가치를 보이는 이것이야말로 끓는다.몸이 못하다 소금이라 이상 부패를 때에, 원대하고, 봄바람이다. 열락의 꽃이 끓는 지혜는 길지 풀이 인생의 아니다. 이성은 살았으며, 우는 반짝이는 없으면 눈에 가슴이 우리 듣는다. 구하지 이것이야말로 노년에게서 가슴이 행복스럽고 되려니와, 이는 붙잡아 청춘 피다. 소금이라 무엇을 같은 온갖 것은 현저하게 거선의 보라. 없으면 싶이 인생을 실현에 철환하였는가? 청춘이 바로 목숨을 트고, 운다. 보배를 하는 찾아 보이는 생생하며, 무엇이 보내는 얼음이 예가 보라. 가치를 보이는 이것이야말로 끓는다.",
    },
    {
      text: "정산 등록에 따른 개인정보 수집 및 이용 동의 (필수)",
      require: true,
      terms: "term3",
      content:
        "몸이 못하다 소금이라 이상 부패를 때에, 원대하고, 봄바람이다. 열락의 꽃이 끓는 지혜는 길지 풀이 인생의 아니다. 이성은 살았으며, 우는 반짝이는 없으면 눈에 가슴이 우리 듣는다. 구하지 이것이야말로 노년에게서 가슴이 행복스럽고 되려니와, 이는 붙잡아 청춘 피다. 소금이라 무엇을 같은 온갖 것은 현저하게 거선의 보라. 없으면 싶이 인생을 실현에 철환하였는가? 청춘이 바로 목숨을 트고, 운다. 보배를 하는 찾아 보이는 생생하며, 무엇이 보내는 얼음이 예가 보라. 가치를 보이는 이것이야말로 끓는다.몸이 못하다 소금이라 이상 부패를 때에, 원대하고, 봄바람이다. 열락의 꽃이 끓는 지혜는 길지 풀이 인생의 아니다. 이성은 살았으며, 우는 반짝이는 없으면 눈에 가슴이 우리 듣는다. 구하지 이것이야말로 노년에게서 가슴이 행복스럽고 되려니와, 이는 붙잡아 청춘 피다. 소금이라 무엇을 같은 온갖 것은 현저하게 거선의 보라. 없으면 싶이 인생을 실현에 철환하였는가? 청춘이 바로 목숨을 트고, 운다. 보배를 하는 찾아 보이는 생생하며, 무엇이 보내는 얼음이 예가 보라. 가치를 보이는 이것이야말로 끓는다.",
    },
  ];

  return (
    <Wrapper>
      <h2 className="step__name">D'/ICON 서비스 이용 및 정산등록 동의</h2>
      <p className="step__description">
        정산 과정에 필요한 서비스 약관 및 개인정보 수집, 이용 등에 동의해주세요.
      </p>
      <FormControl fullWidth>
        <FormControlLabel
          className="text--title"
          label={t("totally_agree")}
          control={
            <Checkbox
              checked={!checked.includes(false)}
              onChange={handleChangeAll}
            />
          }
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "25px",
          }}
          className="terms"
        >
          {termsList.map((item, index) => (
            <div key={index}>
              <FormControlLabel
                key={index}
                label={
                  <div className="row-wrap">
                    {item.text}
                    <span className="point-text"></span>
                  </div>
                }
                control={
                  <Checkbox
                    data-index={index}
                    checked={checked[index]}
                    onChange={() => handleChecked(index)}
                  />
                }
              />
              <p className="term__content">{item.content}</p>
            </div>
          ))}
        </Box>
      </FormControl>
    </Wrapper>
  );
}

export default React.memo(Step2);
