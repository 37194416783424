import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import {
  COLORS,
  FONT_WEIGHT,
  LINE_HEIGHT,
  RADIUS,
  TEXTSIZE,
  WIDTH,
} from "../../theme/constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { postUserQuestion } from "../../api";
import { Box, Button, InputLabel, Typography } from "@mui/material";
import {
  buttonLargeMuiStyle,
  inputMuiStyle,
  textMediumMuiStyle,
} from "../../utils/commonStyle";
import { handleError } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }
`;

const labelStyle = {
  fontSize: TEXTSIZE.large,
  fontWeight: FONT_WEIGHT.xxLarge,
  lineHeight: LINE_HEIGHT.xxxxLarge,
  color: COLORS.black1,
  mb: 1,
};

function UserQuestion() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logout } = useAuth();
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(event?.target?.value);
  };

  const handleSubmit = async () => {
    try {
      const requestData = {
        body: value,
      };
      await postUserQuestion(requestData);
      toast("의견이 D'/ICON로 보내졌어요 :)");
      navigate(-1, { replace: true });
    } catch (error) {
      handleError(error, logout);
    }
  };

  return (
    <Wrapper>
      <Box sx={{ my: 4 }}>
        <InputLabel sx={labelStyle}>이런게 있으면 참 좋을텐데 ~</InputLabel>
        <Typography
          sx={{ ...textMediumMuiStyle, lineHeight: LINE_HEIGHT.xxxLarge }}
        >
          D'/ICON를 사용하면서 불편했던 점, 좋았던 점, 바라는 기능이 있으셨나요?
          의견을 남겨주시면 빠른 피드백으로 보답해 드리겠습니다:) 자유로운
          의견을 남겨주세요.
        </Typography>
      </Box>
      <Box>
        <InputLabel sx={labelStyle}>의견 남기기</InputLabel>
        <TextField
          name={"question"}
          value={value}
          multiline
          rows={12}
          helperText={
            value && value?.length < 10 ? "최소 10글자 이상 입력해 주세요." : ""
          }
          FormHelperTextProps={{
            style: {
              color: COLORS.redError,
            },
          }}
          onChange={handleChange}
          placeholder={"자유롭게 의견을 남겨 주세요 :)"}
          sx={{
            width: "100%",
            borderRadius: RADIUS.large,
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: RADIUS.xxLarge,
              backgroundColor: COLORS.grey50,
              border: "none",
            },
          }}
          inputProps={{
            sx: { ...inputMuiStyle, borderRadius: 0, px: 0 },
            // maxLength: maxLength,
          }}
        />
      </Box>
      <Button
        sx={{ ...buttonLargeMuiStyle, mt: 4 }}
        disabled={value.length < 10}
        variant="contained"
        size="large"
        onClick={handleSubmit}
      >
        {/* 다음 */}
        {t("button.next")}
      </Button>
    </Wrapper>
  );
}

export default React.memo(UserQuestion);
