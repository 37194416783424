import { useState } from "react";
import Register from "../containers/dataTransfer/Register";
import TransferCompleted from "../containers/dataTransfer/TransferCompleted";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Modal, Typography } from "@mui/material";
import ConsentTransferWeb from "../containers/dataTransfer/ConsentTransferWeb";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 40px)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "16px",
  padding: "24px 20px",
  textAlign: "center",
};

const DataTransferWeb = () => {
  const channel = new BroadcastChannel("instagram-connected");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [type, setType] = useState("instagram");
  const [userData, setUserData] = useState();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("error");

  const [tokenFromApi, setTokenFromApi] = useState("");

  const redirectUri = `${process.env.REACT_APP_URL}/instagram-connecting`;
  const clientId = process.env.REACT_APP_INSTAGRAM_CLIENT_ID;
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const popupWidth = 600;
  const popupHeight = 600;
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handPreStep = () => {
    setStep(step - 1);
  };

  const handleChangeType = (value) => {
    setType(value);
  };

  const handleComplete = () => {
    setStep(1);
  };

  const handleConnectYoutube = async (type, url) => {
    const requestBody = {
      resource: type,
      url: url,
    };
    await axios
      .create()
      .post(
        `${REACT_APP_API_URL}/social-account/connect/youtube`,
        requestBody,
        {
          headers: {
            lang: "kr",
            Authorization: `Bearer ${tokenFromApi}`,
          },
        }
      )
      .then((res) => {
        setStep(3);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.message);
        setOpen(true);
        // toast(error?.response?.data?.message);
      });
  };

  console.log(tokenFromApi);

  const handleConnectInstagram = async (onMessageData) => {
    const requestBody = {
      code: "",
      redirect_uri: redirectUri,
      ...onMessageData,
    };

    console.log("token from api: ", tokenFromApi);
    console.log("requestBody", requestBody);

    if (!tokenFromApi) return;

    await axios
      .create()
      .post(
        `${REACT_APP_API_URL}/social-account/connect/instagram`,
        requestBody,
        {
          headers: {
            lang: "kr",
            Authorization: `Bearer ${tokenFromApi}`,
          },
        }
      )
      .then((res) => {
        setStep(3);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.message);
        setOpen(true);
      });
    await channel.close();
  };

  console.log(process.env, redirectUri);

  const handleOk = (type, url) => {
    setType(type);
    if (type === "instagram") {
      // const connectUrl = `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=user_profile,user_media&response_type=code`;
      const uri = `/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=user_profile,user_media&response_type=code`;
      const encodeURI = encodeURIComponent(uri).toString();
      console.log("uri", uri);
      const connectUrl = `https://www.instagram.com/accounts/login/?enable_fb_login=1&platform_app_id=${clientId}&next=${encodeURI}`;
      console.log("connectUrl", connectUrl);
      const left = (screenWidth - popupWidth) / 2;
      const top = (screenHeight - popupHeight) / 2;
      window.open(
        connectUrl,
        "_blank",
        `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`
      );
    } else {
      handleConnectYoutube(type, url);
    }
  };

  channel.onmessage = (msg) => {
    console.log("onmessage", msg.data);
    const onMessageData = {
      code: msg.data,
    };
    handleConnectInstagram(onMessageData);
  };

  return (
    <div style={{ backgroundColor: "#EBEBEB", height: "100svh" }}>
      {step === 1 ? (
        <ConsentTransferWeb
          nextStep={handleNextStep}
          userData={userData}
          setTokenFromApi={setTokenFromApi}
        />
      ) : step === 2 ? (
        <Register
          nextStep={handleNextStep}
          preStep={handPreStep}
          changeType={handleChangeType}
          userData={userData}
          handleOk={handleOk}
        />
      ) : (
        <TransferCompleted
          preStep={handPreStep}
          type={type}
          userData={userData}
          handleComplete={handleComplete}
        />
      )}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ fontSize: "16px", fontWeight: 700, lineHeight: "24px" }}
          >
            알림
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: "16px",
              fontSize: "14px",
              lineHeight: "22px",
              fontWeight: 400,
            }}
          >
            {errorMessage}
          </Typography>
          <Typography
            sx={{
              mt: "24px",
              borderRadius: "8px",
              padding: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: 500,
              color: "#FFF",
              backgroundColor: "#1E1E1E",
              width: "100%",
            }}
            onClick={() => setOpen(false)}
          >
            확인
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default DataTransferWeb;
