import { ReactComponent as LogoIcon } from "@icons/logo.svg";
import { ReactComponent as InstagramIcon } from "@icons/instagram.svg";
import { ReactComponent as YoutubeIcon } from "@icons/youtube.svg";
import arrowRightImage from "@images/arrowRight.svg";
import img from "@images/frame500.png";
const TransferCompleted = ({ type, preStep, userData, handleComplete }) => {
  return (
    <div
      style={{
        margin: "auto",
        backgroundColor: "white",
        wordBreak: "keep-all",
        color: "#1E1E1E",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
      }}
    >
      <div
        style={{
          padding: "16px",
          display: "flex",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <div className="icon-wrap" onClick={preStep}>
          <img
            src={arrowRightImage}
            style={{
              transform: "rotate(180deg)",
              width: "24px",
              height: "24px",
            }}
          />
        </div>

        <div style={{ flex: 1, textAlign: "center" }}>
          <p
            style={{
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "24px",
            }}
          >
            데이터 이관 신청 완료
          </p>
        </div>

        <div
          style={{
            width: "24px",
            height: "24px",
          }}
        ></div>
      </div>

      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          paddingTop: "50px",
        }}
      >
        <div style={{ flex: 1, textAlign: "center" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
          >
            <g clipPath="url(#clip0_7963_121720)">
              <path
                d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z"
                fill="#6329F1"
              />
              <path
                d="M11 19.4545L17.2812 25.9261C17.3205 25.9665 17.3854 25.9665 17.4247 25.9261L29 14"
                stroke="#D7F4FF"
                strokeWidth="4"
                strokeLinecap="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_7963_121720">
                <rect width="40" height="40" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "11px",
            }}
          >
            {type === "instagram" ? <InstagramIcon /> : <YoutubeIcon />}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="72"
              height="8"
              viewBox="0 0 72 8"
              fill="none"
            >
              <path
                d="M0 0H66.9474L72 4L66.9474 8H0V4V0Z"
                fill="url(#paint0_linear_7963_120738)"
                fillOpacity="0.5"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_7963_120738"
                  x1="0"
                  y1="4"
                  x2="65.4"
                  y2="4"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#BDE6FF" stopOpacity="0" />
                  <stop offset="0.354798" stopColor="#BDE6FF" />
                  <stop offset="1" stopColor="#6552FF" />
                </linearGradient>
              </defs>
            </svg>

            <LogoIcon />
          </div>

          <img src={img} alt="" style={{ width: "160px" }} />

          <p
            style={{
              fontWeight: 700,
              marginTop: "24px",
            }}
          >
            {type === "instagram" ? "인스타그램" : "유튜브"}
            계정에서 <br />
            D'/ICON {userData?.nickname} 계정으로의
          </p>

          <p
            style={{
              fontWeight: 700,
              marginTop: "12px",
            }}
          >
            데이터 이관 신청이 완료되었습니다.
          </p>

          <p
            style={{
              marginTop: "40px",
            }}
          >
            데이터 이관이 완료되면 <br />
            D'/ICON 푸시 알림과 이메일을 보내드립니다.
          </p>
        </div>

        <div style={{ padding: "20px" }}>
          <button
            type="submit"
            style={{
              borderRadius: "8px",
              padding: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: 500,
              color: "#FFF",
              backgroundColor: "#1E1E1E",
              width: "100%",
            }}
            onClick={handleComplete}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransferCompleted;
