import React, { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";

function InstagramConnecting() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const channel = new BroadcastChannel("instagram-connected");

  useEffect(() => {
    console.log("code xxxxxx");

    const code = searchParams.get("code");
    channel.postMessage(code);
    console.log("code", code);
    window.close();
  }, [params, searchParams]);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <CircularProgress /> */}
      <Typography
        sx={{
          whiteSpace: "pre-line",
          textAlign: "center",
        }}
      >
        {`인스타그램에 성공적으로 연결되었습니다.\nD'/ICON 연결 상태를 확인하려면 이전 데이터 변환 페이지로 돌아가세요.`}
      </Typography>
    </Box>
  );
}

export default InstagramConnecting;
