import { Button } from "@mui/material";
import React from "react";
import styled from "styled-components";
import appImage from "../../assets/svgs/appIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/svgs/close.svg";
import { COLORS, RADIUS, TEXTSIZE } from "../../theme/constants";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 15px 16px 40px;
  background: var(--bgColor);
  border-radius: ${RADIUS.medium} ${RADIUS.medium} 0 0;

  .button-close {
    position: absolute;
    top: 15px;
    left: 16px;
    cursor: pointer;
  }

  img {
    width: 68px;
    height: 68px;
    margin: 44px auto 18px;
  }

  .text-area {
    font-weight: 400;
    font-size: ${TEXTSIZE.medium};
    line-height: 23.8px;
    text-align: center;

    .bold {
      font-weight: 700;
    }
  }

  .button-download {
    width: 100%;
    height: 56px;
    background-color: var(--mainColor);
    color: ${COLORS.white};
    font-size: ${TEXTSIZE.large};
    font-weight: 600;
    margin: 20px auto;

    &:hover {
      background-color: var(--mainColor);
      text-decoration: underline;
    }
  }

  .button-notToDay {
    font-size: ${TEXTSIZE.small};
    color: ${COLORS.gray_7};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

function AppDownload({ close }) {
  const handleNotToDay = () => {
    close();
    console.log("오늘 안봄?");
  };

  return (
    <Wrapper>
      <button type="button" onClick={close} className="button-close">
        <CloseIcon />
      </button>
      <img src={appImage} alt="picaverse" />
      <div className="text-area">
        <b className="bold">D'/ICON 앱</b>에서 쉽고 편리하게
        <br />
        커넥터들과 소통해보세요.
      </div>
      <Button
        className="button-download"
        onClick={() => console.log("앱 다운로드")}
      >
        앱에서 보기
      </Button>
      <button
        type="button"
        className="button-notToDay"
        onClick={handleNotToDay}
      >
        그냥 볼게요.
      </button>
    </Wrapper>
  );
}

export default React.memo(AppDownload);
