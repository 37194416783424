import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as RightArrow } from "../../assets/svgs/arrow_right.svg";
import {
  COLORS,
  FONT_WEIGHT,
  LINE_HEIGHT,
  TEXTSIZE,
  WIDTH,
} from "../../theme/constants";
import { Link, useNavigate } from "react-router-dom";
import { getUserProfile } from "../../api";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { textMediumMuiStyle, truncateStyle } from "../../utils/commonStyle";
import ModalComponent from "../../components/modal/ModalComponent";
import { handleError } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import { useTranslation } from "react-i18next";
//social image
import kakaoImage from "@images/kakao.png";
import appleImage from "@images/apple.png";
import googleImage from "@images/google.png";
import amazonImage from "@images/amazon.png";
import naverImage from "@images/naver.png";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }

  position: relative;

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 56px;

    &__text {
      font-size: ${TEXTSIZE.medium};
    }

    &.phone {
      .item__text-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    &.password {
      // .item__text-content {
      //   display: flex;
      //   align-items: center;
      //   justify-content: flex-end;
      // }
      cursor: pointer;
    }

    &.withdraw {
      cursor: pointer;
      //   position: absolute;
      //   bottom: 0;
      //   left: 50%;
      //   transform: translateX(-50%);
      //   text-align: center;
      //   justify-content: center;

      //   .withdraw__text {
      //     font-size: ${TEXTSIZE.small};
      //     color: var(--subTextColor);
      //     text-align: center;
      //     cursor: pointer;
      //   }
    }
  }
`;

function SocialIcon({ provider_type }) {
  let icon = "";
  switch (provider_type) {
    case "google":
      icon = googleImage;
      break;
    case "kakao":
      icon = kakaoImage;
      break;
    case "naver":
      icon = naverImage;
      break;
    case "amazon":
      icon = amazonImage;
      break;
    case "apple":
      icon = appleImage;
      break;
    case "firebase_apple":
      icon = appleImage;
      break;

    default:
      break;
  }

  return (
    <Avatar
      alt={provider_type}
      src={icon}
      sx={{ width: 24, height: 24, border: `1px solid ${COLORS.grey50}` }}
    />
  );
}

function Account() {
  const { t } = useTranslation();
  const { logout, setProfile } = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [modalData, setModalData] = useState({
    open: false,
    btnOKText: "",
    btnCancelText: "",
    title: "",
    subTitle: "",
    content: "",
  });

  const onClickDelete = () => {
    setModalData({
      open: true,
      btnOKText: "취소",
      btnCancelText: "확인",
      title: "정말 탈퇴하실 건가요?",
      subTitle: "",
      content: (
        <Box>
          <Typography
            sx={{
              ...textMediumMuiStyle,
              color: COLORS.grey900,
              lineHeight: LINE_HEIGHT.xxxLarge,
              whiteSpace: "pre-line",
            }}
          >
            탈퇴하면 그동안 D'/ICON에서 활동했던
          </Typography>
          <Typography
            sx={{
              ...textMediumMuiStyle,
              color: COLORS.grey900,
              lineHeight: LINE_HEIGHT.xxxLarge,
              whiteSpace: "pre-line",
            }}
          >
            추억이 전부 삭제돼요 :(
          </Typography>
        </Box>
      ),
    });
  };

  //modal action
  const handleOk = () => {
    setModalData({ ...modalData, open: false });
  };

  const handleClose = () => {
    setModalData({ ...modalData, open: false });
    navigate("/delete");
  };

  const handleGetUserProfile = async () => {
    try {
      setIsLoading(true);
      const res = await getUserProfile();
      if (!res) return;
      setUser(res);
      const user = {
        ...res,
        profile: {
          ...res?.user_profile,
        },
      };
      setProfile(user);
      // const newValues = { ...res?.user_profile };
      // delete newValues.hashtag;
      // setValues(newValues);
      setIsLoading(false);
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  useEffect(() => {
    handleGetUserProfile();
  }, []);

  return (
    <Wrapper>
      {!isLoading ? (
        <>
          <Grid container rowGap={4} sx={{ mb: 2 }}>
            {/* nickname */}
            <Grid item xs={6}>
              <Typography
                sx={{
                  ...textMediumMuiStyle,
                  ...truncateStyle,
                  fontWeight: FONT_WEIGHT.large,
                }}
              >
                {t("name")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  gap: "4px",
                }}
              >
                {/* <SocialIcon provider_type={user?.provider_type} /> */}
                <Typography
                  sx={{
                    ...textMediumMuiStyle,
                    ...truncateStyle,
                    textAlign: "right",
                    width: "fit-content",
                    // maxWidth: "50%",
                  }}
                >
                  {user?.user_profile?.nickname}
                </Typography>
              </Box>
            </Grid>

            {/* email */}
            <Grid item xs={6}>
              <Typography
                sx={{
                  ...textMediumMuiStyle,
                  ...truncateStyle,
                  fontWeight: FONT_WEIGHT.large,
                }}
              >
                {t("email")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  gap: "4px",
                }}
              >
                {user?.provider_type != "default" && (
                  <SocialIcon provider_type={user?.provider_type} />
                )}
                <Typography
                  sx={{
                    ...textMediumMuiStyle,
                    ...truncateStyle,
                    textAlign: "right",
                    width: "fit-content",
                    // maxWidth: "50%",
                  }}
                >
                  {user?.email}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {localStorage.getItem("isAccountLogin") && (
            <Link to={"/change-password"}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: 2,
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    ...textMediumMuiStyle,
                    ...truncateStyle,
                    fontWeight: FONT_WEIGHT.large,
                    maxWidth: "50%",
                  }}
                >
                  {t("change_password")}
                </Typography>
                <RightArrow className="arrow-icon" fill={COLORS.gray_7} />
              </Box>
            </Link>
          )}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                ...textMediumMuiStyle,
                ...truncateStyle,
                fontWeight: FONT_WEIGHT.large,
                maxWidth: "50%",
                color: COLORS.grey500,
                cursor: "pointer",
                py: 2,
              }}
              onClick={onClickDelete}
            >
              {t("withdrawal")}
            </Typography>
          </Box>
        </>
      ) : null}

      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => handleClose()}
        handleOk={() => handleOk()}
        handleCancel={() => handleClose()}
        btnOKProps={{}}
        btnCancelProps={{}}
      />
    </Wrapper>
  );
}

export default React.memo(Account);
