import React, { useState } from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";
import {
  COLORS,
  HEIGHT,
  LINE_HEIGHT,
  TEXTSIZE,
  WIDTH,
} from "../../theme/constants.js";
import { ReactComponent as LOGOLIGHT } from "@icons/logo-light.svg";
import Logo from "@icons/logo.png";

import {
  Box,
  Button,
  FormHelperText,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LoginWithSocial from "./LoginWithSocial.jsx";
import { loginWithAccount } from "../../api.js";
import { useAuth } from "../../context/Auth.js";
import clearImage from "../../assets/images/clear.svg";
import {
  textMediumMuiStyle,
  textSmallMuiStyle,
  labelMediumMuiStyle,
  textFieldMuiStyle,
  inputMuiStyle,
  inputAdornmentMuiStyle,
  buttonLargeMuiStyle,
  textLargeMuiStyle,
} from "../../utils/commonStyle.js";
import { validateEmail } from "../../utils/commonFunction.js";
import ModalComponent from "../../components/modal/ModalComponent.jsx";
import useModalReducer, { MODAL_STATUS } from "../../hooks/useModalReducer.js";
import { useTranslation } from "react-i18next";
import HeaderBack from "../../components/header/HeaderBack.jsx";

const Wrapper = styled.div`
  position: relative;
  height: calc(
    calc(var(--vh, 1vh) * 100) - ${HEIGHT.header_mo} - ${HEIGHT.navigation}
  );

  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }

  .login__section {
    position: relative;
    top: 13%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 205px;
  }

  .section {
    position: absolute;
    width: calc(100% - 32px);
    bottom: 0;
  }
`;

const LIMIT_LENGTH = 16;

function LoginWithAccount(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { login } = useAuth();
  const [errors, setErrors] = useState({});
  const [requestData, setRequestData] = useState({
    provider_type: "default",
    provider_token: null,
    email: "",
    password: "",
    device_id: "",
    device_token: "",
  });
  const { modalData, dispatchModal } = useModalReducer();

  const handleChange = (key, value) => {
    let errorMessage = "";
    if (key === "email" && value && !validateEmail(value)) {
      errorMessage = t("invalid_email_format");
    }
    setErrors({ ...errors, [key]: [errorMessage] });
    setRequestData({ ...requestData, [key]: value });
  };

  const handleOnKeyDown = (e) => {
    if (
      e.key === "Enter" &&
      requestData.email &&
      requestData.password &&
      !errors?.email?.[0] &&
      !errors?.password?.[0]
    ) {
      handleLogin();
    }
  };

  const handleResponseErrors = (responseErr) => {
    setErrors({ ...errors, ...responseErr });
  };

  const handleShowModalErrors = (errorData) => {
    dispatchModal({
      type: MODAL_STATUS.OPEN,
      data: {
        btnOKText: "확인",
        btnCancelText: errorData?.alert_type === 2 && "취소",
        title: errorData?.title,
        subTitle: "",
        content: (
          <Typography
            sx={{
              ...textMediumMuiStyle,
              color: COLORS.grey900,
              lineHeight: LINE_HEIGHT.xxxLarge,
              whiteSpace: "pre-line",
            }}
          >
            {errorData?.description}
          </Typography>
        ),
      },
    });
  };

  const handleLogin = async () => {
    try {
      const tmpRequestData = {
        ...requestData,
        device_id: uuid(),
        device_model: window.navigator.platform,
        device_os: "web",
      };
      const res = await loginWithAccount(tmpRequestData);
      localStorage.setItem("isAccountLogin", true);
      login(res);

      if (res?.user?.is_signup) {
        navigate("/signup/profile-settings");
      } else {
        navigate("/");
      }
    } catch (error) {
      switch (error?.response?.data?.code) {
        case 400:
          handleResponseErrors({ other: [error?.response?.data?.message] });
          break;
        case 403:
          handleShowModalErrors(error?.response?.data?.data);
          break;
        default:
          handleResponseErrors(error?.response?.data?.errors);
          break;
      }
    }
  };

  const onResetPassword = () => {
    navigate("/login/identify");
  };

  return (
    <>
      <Wrapper>
        <HeaderBack title={t("page_title.identify")} />
        <Box className="login__section">
          <img src={Logo} />
          <p
            style={{
              ...textSmallMuiStyle,
              color: COLORS.grey400,
              fontWeight: 700,
              justifyContent: "flex-start",
            }}
          >
            {t("create_your_own_world_with_content")}
          </p>
        </Box>
        <Box className="section">
          <Box>
            <Box sx={{ mt: 2 }}>
              <InputLabel sx={labelMediumMuiStyle}>{t("id_email")}</InputLabel>
              <TextField
                name="email"
                value={requestData.email}
                onChange={(event) => handleChange("email", event.target.value)}
                onKeyDown={handleOnKeyDown}
                placeholder={t("please_enter_your_email_address")}
                sx={textFieldMuiStyle}
                inputProps={{
                  sx: inputMuiStyle,
                }}
                InputProps={{
                  endAdornment: requestData.email.length > 0 && (
                    <InputAdornment
                      position="end"
                      sx={inputAdornmentMuiStyle}
                      onClick={() => handleChange("email", "")}
                    >
                      <img src={clearImage} />
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText
                sx={{
                  ...textMediumMuiStyle,
                  mt: 1,
                  color: COLORS.redError,
                  fontSize: TEXTSIZE,
                  visibility: errors?.email?.[0] ? "visible" : "hidden",
                }}
              >
                {errors?.email?.[0]}
              </FormHelperText>
            </Box>
            <Box sx={{ mt: 2 }}>
              <InputLabel sx={labelMediumMuiStyle}>{t("password")}</InputLabel>
              <TextField
                name="password"
                type="password"
                value={requestData.password}
                onChange={(event) =>
                  handleChange("password", event.target.value)
                }
                onKeyDown={handleOnKeyDown}
                placeholder={t("please_enter_a_password")}
                sx={textFieldMuiStyle}
                inputProps={{
                  sx: inputMuiStyle,
                  maxLength: LIMIT_LENGTH,
                }}
                InputProps={{
                  endAdornment: requestData.password.length > 0 && (
                    <InputAdornment
                      position="end"
                      sx={inputAdornmentMuiStyle}
                      onClick={() => handleChange("password", "")}
                    >
                      <img src={clearImage} />
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText
                sx={{
                  ...textMediumMuiStyle,
                  mt: 1,
                  color: COLORS.redError,
                  fontSize: TEXTSIZE.small,
                  visibility: errors?.password?.[0] ? "visible" : "hidden",
                }}
              >
                {errors?.password?.[0]}
              </FormHelperText>
            </Box>
          </Box>
          <Box sx={{ marginTop: "1.75rem" }}>
            <Typography
              sx={{
                ...textMediumMuiStyle,
                fontSize: "12px",
                cursor: "pointer",
              }}
              onClick={onResetPassword}
            >
              {t("forgot_password")}
            </Typography>
            <Button
              sx={{
                ...buttonLargeMuiStyle,
                backgroundColor: COLORS.primary4,
                color: COLORS.white,
                margin: "0.75rem 0 1.75rem 0",
              }}
              disabled={
                !requestData.email || !requestData.password
                // !!errors?.email?.[0] ||
                // !!errors?.password?.[0]
              }
              variant="contained"
              size="large"
              className="submit"
              onClick={handleLogin}
            >
              {t("button.sign_in")}
            </Button>
          </Box>
          <Typography sx={{ ...textLargeMuiStyle, lineHeight: "20px" }}>
            {t("easy_login_with_another_account")}
          </Typography>
          <LoginWithSocial />
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              mt: "1.5rem",
            }}
          >
            <Typography
              sx={{ ...textMediumMuiStyle, fontSize: "12px", mr: "0.25rem" }}
            >
              {t("what_if_you_are_not_yet_a_D'/ICON_member")}
            </Typography>
            <Link to={"/auth"}>
              <Typography
                sx={{
                  ...textSmallMuiStyle,
                  fontWeight: 700,
                  color: COLORS.black1,
                  textDecoration: "underline",
                }}
              >
                {t("join")}
              </Typography>
            </Link>
          </Box>
        </Box>
      </Wrapper>
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        handleOk={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        handleCancel={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        btnOKProps={{}}
        btnCancelProps={{}}
      />
    </>
  );
}

export default LoginWithAccount;
