import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  COLORS,
  FONT_WEIGHT,
  LINE_HEIGHT,
  RADIUS,
  TEXTSIZE,
} from "@theme/constants";
import { buttonMuiStyle } from "../../utils/commonStyle";
import { useTranslation } from "react-i18next";
import { Box, Button, List, Modal, Typography } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import CloseIcon from "@mui/icons-material/Close";
import { CheckoutPage } from "../payment/CheckoutPage";
import { SuccessPage } from "../payment/Success";
import { FailPage } from "../payment/Fail";
import { getProducts } from "@api";
import axios from "axios";
import { useAuth } from "../../context/Auth";
import { postStorePayment } from "../../api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "600px",
  width: "100%",
  bgcolor: "white",
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: 0,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
  "& .MuiAccordionSummary-root": {
    padding: "0 !important",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "1rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    border: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: COLORS.grey25,
  borderRadius: RADIUS.xxLarge,
  color: COLORS.grey400,
  fontSize: TEXTSIZE.medium,
  paddingTop: "16px !important",
}));

function PaymentDrawer({ close, handleModal }) {
  const { t } = useTranslation();
  const [product, setProduct] = React.useState();
  const [model, setModel] = React.useState("");
  const [products, setProducts] = useState([]);
  const { user } = useAuth();
  // const handlePayment = () => {};

  const handleCallback = (value) => {
    setModel(value);
  };

  const init_orderid = () => {
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();
    let time = today.getTime();

    if (parseInt(month) < 10) {
      month = "0" + month;
    }

    if (parseInt(date) < 10) {
      date = "0" + date;
    }

    return "Danal_" + year + "" + month + "" + date + "" + time;
  };

  useEffect(() => {
    getProducts().then((res) => {
      if (res) {
        setProducts(res?.list);
      }
    });
  }, []);

  useEffect(() => {
    if (product) {
      try {
        const orderid = init_orderid();
        const obj = {
          amount: product?.price?.KRW?.price,
          itemname: product?.name,
          useragent: "WP",
          orderid,
          userid: user?.id,
          useremail: user?.email,
          username: user?.user_profile?.nickname,
          return_url: `${window.location.origin}/payment-connecting?orderid=${orderid}&status=true`,
          cancel_url: `${window.location.origin}/payment-connecting?orderid=${orderid}`,
          bypass_value: `user_product_id=${product?.code}`,
        };
        const data = new FormData();

        Object.entries(obj)?.forEach((item) => {
          data.append(item[0], item[1]);
        });

        const axiosPayment = axios.create();
        const win = window.open("about:blank", "_blank");
        win?.document?.write(`<!DOCTYPE html>
                    <html>
                    <head>
                    <meta name="viewport" content="width=device-width, initial-scale=1">
                    <style>
                    .loader {
                      border: 8px solid #f3f3f3;
                      border-radius: 50%;
                      border-top: 8px solid #3498db;
                      width: 60px;
                      height: 60px;
                      margin: auto;
                      margin-top: 200px;
                      -webkit-animation: spin 2s linear infinite; /* Safari */
                      animation: spin 2s linear infinite;
                    }
                    
                    /* Safari */
                    @-webkit-keyframes spin {
                      0% { -webkit-transform: rotate(0deg); }
                      100% { -webkit-transform: rotate(360deg); }
                    }
                    
                    @keyframes spin {
                      0% { transform: rotate(0deg); }
                      100% { transform: rotate(360deg); }
                    }
                    </style>
                    </head>
                    <body>
                    <div class="loader"></div>
                    </body>
                    </html>`);
        axiosPayment
          .post(process.env.REACT_APP_PAYMENT_PHP_API, data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            const value = res?.data?.data;

            postStorePayment({
              user_id: user?.id,
              product_code: product?.code,
              orderid,
              amount: product?.price?.KRW?.price,
            })
              .then((res) => {
                if (res?.status === 201) {
                  win?.document
                    ?.write(`<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
                    <html xmlns="http://www.w3.org/1999/xhtml" >
                    <head>
                    <meta http-equiv="Content-Type" content="text/html; charset=euc-kr">
                    <link href="./css/style.css" type="text/css" rel="stylesheet"  media="all" />
                    <title>*** �ٳ� �ſ�ī�� ���� ***</title>
                    </head>
                    <body>
                        <form name="form" ACTION="https://checkout.teledit.com/creditcard_std/web" METHOD="POST" >
                        <input TYPE="HIDDEN" NAME="STARTPARAMS"  	VALUE="${value}">
                        </form>
                        <script>
                            document.form.submit();
                        </script>
                    </body>`);
                  setProduct();
                }
              })
              .catch((error) => {
                console.log("error", error);
              });
          })
          .catch((error) => {
            console.log("error", error);
          });
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [product]);

  return (
    <Box sx={{ margin: 2 }}>
      <List
        className="list-drawer"
        sx={{
          background: COLORS.white,
          borderRadius: RADIUS.large,
          mb: 2,
          p: "16px",
        }}
      >
        <div style={{ textAlign: "right" }}>
          <CloseIcon
            onClick={close}
            sx={{ fontSize: "1.2rem", cursor: "pointer" }}
          />
        </div>
        <Typography
          sx={{
            fontSize: TEXTSIZE.xxLarge,
            fontWeight: FONT_WEIGHT.xxLarge,
            color: COLORS.black1,
            marginBottom: "10px",
          }}
        >
          PIC 충전하기
        </Typography>
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "70vh",
            "&::-webkit-scrollbar": { display: "none" },
            pt: "20px",
          }}
        >
          {products?.map((item) => (
            <Box
              key={item?.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
                backgroundColor: COLORS.grey25,
                borderRadius: RADIUS.xLarge,
                marginBottom: "12px",
              }}
            >
              <Typography
                sx={{
                  fontSize: TEXTSIZE.large,
                  lineHeight: LINE_HEIGHT.xLarge,
                  fontWeight: FONT_WEIGHT.xxLarge,
                  color: COLORS.black1,
                  width: "33%",
                }}
              >
                {new Intl.NumberFormat().format(item?.pic)}
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: FONT_WEIGHT.large,
                  }}
                >
                  PIC
                </Typography>
              </Typography>
              <Typography
                sx={{
                  fontSize: TEXTSIZE.large,
                  lineHeight: LINE_HEIGHT.xLarge,
                  fontWeight: FONT_WEIGHT.xxLarge,
                  color: COLORS.black1,
                  width: "34%",
                }}
              >
                {new Intl.NumberFormat().format(item?.price?.KRW?.price)}
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: FONT_WEIGHT.large,
                  }}
                >
                  {t("price")}
                </Typography>
              </Typography>
              <Button
                sx={{
                  ...buttonMuiStyle,
                  padding: "4px 12px",
                  width: "fit-content",
                  backgroundColor: COLORS.primary4,
                  color: COLORS.white,
                }}
                variant="contained"
                className="submit"
                onClick={() => setProduct(item)}
              >
                충전하기
              </Button>
            </Box>
          ))}

          <Accordion defaultExpanded>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography
                sx={{
                  fontSize: TEXTSIZE.large,
                  fontWeight: FONT_WEIGHT.xxLarge,
                  color: COLORS.black1,
                }}
              >
                PIC 환불 규정
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                - 환불이란 소진된 유료 PIC을 결제한 수단 또는 현금으로 회원에게
                다시 돌려주는 것을 뜻합니다. 단, 카드 결제 건에 대한 환불은 카드
                매출 취소로만 가능하며, 현금으로 환불처리는 불가능합니다. - 무료
                PIC은 환불의 범위에서 제외됩니다. - PIC의 환불은 구매한 PIC 중
                일부가 사용되지 않은 상태에서, 구매로부터 7일 이내에 요청한
                경우에 가능하며, 일부가 사용되었거나 7일이 지난 경우에는 환불이
                불가능합니다. - 주민등록법, 저작권법, 컴퓨터프로그램보호법,
                정보통신망법 등 각종 법령에 대한 중대한 불법행위 또는 약관 내
                명시된 내용과 같이 D'/ICON 운영에 심각한 악영향을 미치는 행위를
                한 것으로 판단되는 이용자의 계정 및 아이디 이용을 제한하거나
                해당 이용자와의 계약을 해지하는 경우, D'/ICON는 이용료를
                환불하지 않을 수 있습니다. 다만, 회원님이 중대한 불법행위 또는
                D'/ICON 운영에 심각한 영향을 미치는 행위를 하지 않았다는 것을
                소명하는 경우에는 그러하지 않습니다. - D'/ICON는 회원님이 충전한
                PIC을 사용하지 않았고, 결제대행사를 통해 전액 취소 처리가 가능한
                경우, 즉각 취소 처리를 진행합니다. 그렇지 않은 경우에는 회원님이
                환불 요청 시 기입한 은행 계좌를 통해 송금하며, 경우에 따라 은행
                송금 수수료가 발생하는 경우, 이를 제외한 금액으로 입금
                처리합니다. - 상기 항목에 따라 환불이 가능한 경우에도 회원님이
                결제 시 이용한 특정 결제 수단을 제공하는 결제대행사 혹은
                금융사의 요청이 있을 경우, 환불이 제한되거나 보류될 수 있습니다.
                이에 해당하는 결제 수단의 경우, 별도 안내를 통해 확인할 수
                있으며, D'/ICON는 특정 결제 수단에 대한 결제대행사 혹은 금융사의
                정책 변경이 있을 경우 즉각 고지, 안내합니다. - 다음의 각 항에
                해당하는 경우, 환불 적용이 불가합니다. : 콘텐츠에 대한 불만 :
                구매 실수로 인한 환불 요청
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </List>

      {/* <Modal
        open={!!product}
        onClose={() => {
          setProduct();
          setModel("");
          model?.name === "success" && close();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ textAlign: "right", padding: 4 }}>
            <CloseIcon
              onClick={() => {
                setProduct();
                setModel("");
              }}
              sx={{ fontSize: "1.2rem", cursor: "pointer" }}
            />
          </div>
          {model?.name === "success" ? (
            <SuccessPage data={model} handleModal={handleModal} close={close} />
          ) : model?.name === "fail" ? (
            <FailPage />
          ) : (
            <CheckoutPage product={product} callback={handleCallback} />
          )}
        </Box>
      </Modal> */}
    </Box>
  );
}

export default React.memo(PaymentDrawer);
