import arrowRightImage from "@images/arrowRight.svg";
import { Box, Modal, Typography } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../theme/constants";
import axios from "axios";

const styleError = {
  fontWeight: "400",
  fontSize: "10px",
  lineHeight: "16px",
  color: `${COLORS.redError}`,
  whiteSpace: "pre-line",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 40px)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "16px",
  padding: "24px 20px",
  textAlign: "center",
};
const Dot = () => {
  return (
    <div
      style={{
        width: "4px",
        minWidth: "4px",
        height: "4px",
        borderRadius: "4px",
        backgroundColor: "#3B3B3B",
        margin: "6px",
        marginLeft: 0,
      }}
    ></div>
  );
};

const CheckBox = ({ onChange, value }) => {
  return (
    <div
      onClick={() => onChange(!value)}
      style={{ cursor: "pointer", display: "flex", gap: "8px" }}
    >
      <div
        style={{
          width: "24px",
          height: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "4px",
          backgroundColor: value ? "#1E1E1E" : "white",
          border: "1px solid #D9D9D9",
          borderColor: value ? "#1E1E1E" : "#D9D9D9",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M3 8L6.75 12L13 4"
            stroke={value ? "white" : "#D9D9D9"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      <p
        style={{
          fontSize: "14px",
          lineHeight: "18px",
          fontWeight: 500,
          marginTop: "3px",
        }}
      >
        데이터 이관 동의
      </p>
    </div>
  );
};
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const ConsentTransferWeb = ({ nextStep, userData, setTokenFromApi }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("error");

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      email: "",
      nickname: "",
      isAccess: false,
    },
  });

  const onSubmit = async (data) => {
    delete data.isAccess;
    console.log("data", data);
    const requestBody = {
      ...data,
    };
    await axios
      .create()
      .post(`${REACT_APP_API_URL}/auth/login-email`, requestBody, {
        headers: {
          lang: "kr",
          // Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log("res", res?.data?.data?.meta?.access_token?.token);
        const token = res?.data?.data?.meta?.access_token?.token;
        console.log("token response", res);
        setTokenFromApi(token);
        // setOpen(true);
        nextStep();
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.message);
        setErrorOpen(true);
      });
  };

  useEffect(() => {
    setValue("email", userData?.email, {
      shouldValidate: true,
    });
    setValue("nickname", userData?.nickname, {
      shouldValidate: true,
    });
  }, [userData]);

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          //   maxWidth: "360px",
          margin: "auto",
          backgroundColor: "white",
          wordBreak: "keep-all",
          color: "#1E1E1E",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div
          style={{
            padding: "16px",
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <div className="icon-wrap" onClick={() => console.log("click terms")}>
            <img
              src={arrowRightImage}
              style={{
                transform: "rotate(180deg)",
                width: "24px",
                height: "24px",
              }}
            />
          </div>

          <div style={{ flex: 1, textAlign: "center" }}>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "24px",
              }}
            >
              데이터 이관 동의
            </p>
          </div>

          <div
            style={{
              width: "24px",
              height: "24px",
            }}
          ></div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ flex: 1, display: "flex", flexDirection: "column" }}
        >
          <div style={{ flex: 1 }}>
            <div
              style={{ padding: "0 20px", borderBottom: "1px solid #EBEBEB" }}
            >
              <div
                style={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: "8px",
                  padding: "20px 16px",
                  fontSize: "12px",
                  lineHeight: "16px",
                  fontWeight: 400,
                  color: "#3B3B3B",
                }}
              >
                <p>
                  D'/ICON는 사용자의 요청에 따라 데이터 이관 작업을 대행하고
                  있습니다.
                </p>

                <div style={{ display: "flex" }}>
                  <Dot />
                  <p>
                    수집된 콘텐츠 정보는 인증된 개인의 D'/ICON 페이지에서만
                    보관됩니다.
                  </p>
                </div>
                <div style={{ display: "flex", marginTop: "12px" }}>
                  <div
                    style={{
                      marginLeft: "8px",
                      marginRight: "4px",
                      width: "4px",
                    }}
                  >
                    -
                  </div>
                  <p style={{ letterSpacing: "-0.24px" }}>
                    D'/ICON 계정 1개당 1개의 소셜미디어 채널의 콘텐츠만 이관이
                    가능합니다.
                  </p>
                </div>
                <div style={{ display: "flex", marginTop: "4px" }}>
                  <div
                    style={{
                      marginLeft: "8px",
                      marginRight: "4px",
                      width: "4px",
                    }}
                  >
                    -
                  </div>
                  <p style={{ letterSpacing: "-0.24px" }}>
                    D'/ICON 계정에서 데이터 이관을 완료한 소셜미디어 채널의
                    데이터를 또다른 D'/ICON 계정에서 이관할 수 없습니다.
                  </p>
                </div>

                <div style={{ display: "flex", marginTop: "8px" }}>
                  <Dot />
                  <p>
                    타인의 콘텐츠를 동의없이 무단으로 이관할 시에 책임은 신청자
                    본인에게 있습니다.
                  </p>
                </div>

                <div style={{ display: "flex", marginTop: "8px" }}>
                  <Dot />
                  <p>
                    D'/ICON 데이터 이관은 원본 소셜미디어 채널(유튜브,
                    인스타그램)에 절대 영향을 끼치지 않습니다.
                  </p>
                </div>
              </div>

              <div style={{ marginTop: "15px", marginBottom: "26px" }}>
                <CheckBox
                  onChange={(value) =>
                    setValue("isAccess", value, {
                      shouldValidate: true,
                    })
                  }
                  value={getValues("isAccess")}
                />
              </div>
            </div>

            <div style={{ padding: "24px 20px 20px" }}>
              <div>
                <p
                  style={{
                    fontSize: "14px",
                    lineHeight: "18px",
                    fontWeight: 500,
                  }}
                >
                  D'/ICON 계정 이메일
                </p>

                <input
                  type="email"
                  style={{
                    padding: "12px",
                    borderRadius: "8px",
                    border: "1px solid #D9D9D9",
                    width: "100%",
                    marginTop: "8px",
                  }}
                  placeholder="example@email.com"
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i,
                      message: t("invalid_email_format"),
                    },
                  })}
                />
                {/* {errors.email && (
                  <Typography style={styleError}>{errors["email"]?.message}</Typography>
                )} */}
              </div>

              <div style={{ marginTop: "20px" }}>
                <p
                  style={{
                    fontSize: "14px",
                    lineHeight: "18px",
                    fontWeight: 500,
                  }}
                >
                  D'/ICON 계정 이름
                </p>

                <input
                  type="text"
                  style={{
                    padding: "12px",
                    borderRadius: "8px",
                    border: "1px solid #D9D9D9",
                    width: "100%",
                    marginTop: "8px",
                  }}
                  placeholder="계정 이름을 입력해 주세요."
                  {...register("nickname", {
                    required: true,
                  })}
                />
                {/* {errors.nickname && (
                  <Typography style={styleError}>{errors["nickname"]?.message}</Typography>
                )} */}
              </div>
            </div>
          </div>
          <div style={{ padding: "20px" }}>
            <button
              type="submit"
              style={{
                borderRadius: "8px",
                padding: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: 500,
                color: getValues("isAccess") && isValid ? "#FFF" : "#707070",
                backgroundColor:
                  getValues("isAccess") && isValid ? "#1E1E1E" : "#EBEBEB",
                width: "100%",
              }}
              disabled={!isValid || !getValues("isAccess")}
              //   onClick={() => setOpen(true)}
            >
              확인
            </button>
          </div>
        </form>
      </div>
      {/* <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ fontSize: "16px", fontWeight: 700, lineHeight: "24px" }}
          >
            알림
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: "16px",
              fontSize: "14px",
              lineHeight: "22px",
              fontWeight: 400,
            }}
          >
            데이터 이관을 이미 완료하였습니다.
          </Typography>
          <Typography
            sx={{
              mt: "24px",
              borderRadius: "8px",
              padding: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: 500,
              color: "#FFF",
              backgroundColor: "#1E1E1E",
              width: "100%",
            }}
            onClick={nextStep}
          >
            확인
          </Typography>
        </Box>
      </Modal> */}

      <Modal
        open={errorOpen}
        onClose={() => setErrorOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ fontSize: "16px", fontWeight: 700, lineHeight: "24px" }}
          >
            알림
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: "16px",
              fontSize: "14px",
              lineHeight: "22px",
              fontWeight: 400,
            }}
          >
            {errorMessage}
          </Typography>
          <Typography
            sx={{
              mt: "24px",
              borderRadius: "8px",
              padding: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: 500,
              color: "#FFF",
              backgroundColor: "#1E1E1E",
              width: "100%",
            }}
            onClick={() => setErrorOpen(false)}
          >
            확인
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default ConsentTransferWeb;
