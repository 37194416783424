import React, { useContext } from "react";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "@theme/constants";

import { ReactComponent as Home } from "@icons/home.svg";
import { ReactComponent as Search } from "@icons/search.svg";
import { ReactComponent as Smile } from "@icons/smile.svg";
import { ReactComponent as MessageIcon } from "@icons/message.svg";
import { ReactComponent as PurchaseIcon } from "@icons/purchase.svg";

import { ThemeContext } from "../../context/themeProvider";
import { Box, Typography } from "@mui/material";
import { GlobalStateContext } from "../../context/GlobalStateProvider";
import { animateScroll } from "react-scroll";
import { useMediaQuery } from "react-responsive";
import { WIDTH } from "../../theme/constants";
import mainImage from "@images/main-image.png";

const Wrapper = styled.header`
  position: sticky;
  display: block;
  width: 300px;
  height: 100vh;
  background: transparent;

  .logo {
    // padding: 28px 40px 32px 40px;
    height: 56px;
    cursor: pointer;
  }

  .header-inner {
    position: fixed;
    width: 300px;
    height: 100vh;
    background: transparent;
  }
`;

const WrapperNew = styled.div`
   {
    .icon-dark {
      path {
        fill: ${COLORS.grey500};
      }
    }
    .icon-light {
      path {
        fill: ${COLORS.grey500};
      }
    }

    .icon-dark__active {
      path {
        fill: ${COLORS.white};
      }
    }
    .icon-light__active {
      path {
        fill: ${COLORS.black1};
      }
    }

    .icon-light-smile,
    .icon-dark-smile {
      circle {
        stroke: ${COLORS.grey500};
      }
      path {
        stroke: ${COLORS.grey500};
      }
    }

    .icon-dark-smile__active {
      circle {
        stroke: ${COLORS.white};
      }
      path {
        stroke: ${COLORS.white};
      }
    }

    .icon-light-smile__active {
      circle {
        stroke: ${COLORS.black1};
      }
      path {
        stroke: ${COLORS.black1};
      }
    }

    .icon-light-purchase,
    .icon-dark-purchase {
      width: 24px;
      height: 24px;
      circle {
        stroke: ${COLORS.grey500};
      }
      path {
        fill: ${COLORS.grey500};
      }
    }

    .icon-light-purchase__active {
      width: 24px;
      height: 24px;
      circle {
        stroke: ${COLORS.black1};
      }
      path {
        fill: ${COLORS.black1};
      }
    }

    .icon-dark-purchase__active {
      width: 24px;
      height: 24px;
      circle {
        stroke: ${COLORS.white};
      }
      path {
        fill: ${COLORS.white};
      }
    }

    .icon-dark-message {
      path {
        stroke: ${COLORS.grey500};
      }
    }

    .icon-dark-message__active {
      path {
        stroke: ${COLORS.white};
      }
    }

    .icon-light-message {
      path {
        stroke: ${COLORS.grey500};
      }
    }

    .icon-light-message__active {
      path {
        stroke: ${COLORS.black1};
      }
    }

    .circle-icon-dark {
      circle {
        fill: ${COLORS.primary8};
      }
    }
  }
`;

function NavigateChat(props) {
  const { isGuideline = true } = props;
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const location = useLocation();
  const homeMatch = useMatch("/");
  const exploreMatch = useMatch("/explore");
  const searchMatch = useMatch("/search");
  const createMatch = useMatch("/post/create");
  const { backgroundHomeType } = useContext(GlobalStateContext);
  // const backgroundHomeType = localStorage.getItem("backgroundHomeType");

  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });

  const onClickLogo = () => {
    navigate("/");
    animateScroll.scrollToTop({
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      containerId: "scrollableContent",
    });
  };

  const scrollToTop = (link) => {
    if (link === "/") {
      animateScroll.scrollToTop({
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        containerId: "scrollableContent",
      });
    }
  };

  const isBackgroundImage = () => {
    return false;
  };

  // const isBackgroundImage = () => {
  //   return backgroundHomeType === 1;
  // };

  const lightNavigates = [
    {
      link: "/",
      icon: (
        <Home
          style={{
            fill: "transparent",
            stroke: COLORS.grey500,
          }}
        />
      ),
      iconActive: (
        <Home
          style={{
            fill: "transparent",
            stroke: isBackgroundImage() ? COLORS.grey100 : COLORS.black1,
          }}
        />
      ),
      title: "Feed",
      isActive:
        homeMatch || (location.pathname.includes("post") && !createMatch),
    },
    {
      link: "/search",
      icon: (
        <Search className={`icon-${isBackgroundImage() ? "dark" : "light"}`} />
      ),
      iconActive: (
        <Search
          className={`icon-${
            isBackgroundImage() ? "dark__active" : "light__active"
          }`}
        />
      ),
      title: "Search",
      isActive:
        searchMatch ||
        location.pathname.includes("/search/feed") ||
        location.pathname.includes("/search/details/category") ||
        location.pathname.includes("/search/details/keyword") ||
        location.pathname.includes("/search/category"),
    },
    {
      icon: (
        <Box sx={{ width: "24px", height: "24px" }}>
          <img src={mainImage} />
        </Box>
      ),
      iconActive: (
        <Box sx={{ width: "24px", height: "24px" }}>
          <img src={mainImage} />
        </Box>
      ),
      link: "/post/create",
      title: "Create Post",
      isActive: createMatch,
    },
    {
      link: "/chat",
      icon: (
        <MessageIcon
          className={`icon-${
            isBackgroundImage() ? "dark-message" : "light-message"
          }`}
        />
      ),
      iconActive: (
        <MessageIcon
          className={`icon-${
            isBackgroundImage()
              ? "dark-message__active"
              : "light-message__active"
          }`}
        />
      ),
      title: "Message",
      isActive: location.pathname.includes("/chat"),
    },
    {
      link: "/profile",
      icon: (
        <Smile
          className={`icon-${
            isBackgroundImage() ? "dark-smile" : "light-smile"
          }`}
        />
      ),
      iconActive: (
        <Smile
          className={`icon-${
            isBackgroundImage() ? "dark-smile__active" : "light-smile__active"
          }`}
        />
      ),
      title: "MY",
      isActive:
        location.pathname.includes("/profile") &&
        !location.pathname.includes("/profile/history"),
    },
    {
      link: "/calculate",
      icon: (
        <PurchaseIcon
          className={`icon-${
            isBackgroundImage() ? "dark-purchase" : "light-purchase"
          }`}
        />
      ),
      iconActive: (
        <PurchaseIcon
          className={`icon-${
            isBackgroundImage()
              ? "dark-purchase__active"
              : "light-purchase__active"
          }`}
        />
      ),
      title: "MyPIC",
      isActive: location.pathname.includes("/calculate"),
    },
  ];

  const communityGuidelines = [
    // { title: "Community Guideline", link: "/" },
    { title: "개인정보처리방침", link: "/policy" },
    { title: "이용약관", link: "/terms" },
  ];
  const isFoldSmall = false;

  if (!isPc) return;

  return (
    <Wrapper>
      <div className="header-inner">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "56px",
            pl: "40px",
            cursor: "pointer",
          }}
          onClick={onClickLogo}
        >
          {theme === "dark" ? <img src={Logo} /> : <img src={Logo} />}
        </Box>
        <WrapperNew>
          <Box sx={{ px: "40px", py: "16px" }}>
            {!location?.pathname.includes("join") &&
              lightNavigates.map((item) => (
                <Link
                  to={item.link}
                  key={item.link}
                  onClick={() => scrollToTop(item.link)}
                >
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        // flexDirection: "column",
                        alignItems: "center",
                        gap: 1.5,
                        py: "1.25rem",
                      }}
                    >
                      {item?.isActive ? item.iconActive : item.icon}
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "20px",
                          lineHeight: "26px",
                          color: item?.isActive
                            ? isBackgroundImage()
                              ? COLORS.white
                              : COLORS.black1
                            : COLORS.grey500,
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              ))}
          </Box>
        </WrapperNew>

        {/* {isGuideline && (
          <Box
            sx={{
              position: "fixed",
              bottom: "44px",
              display: "flex",
              flexDirection: "column",
              gap: 2.5,
              px: "40px",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "26px",
                color: COLORS.grey500,
              }}
            >
              Community Guideline
            </Typography>
            {communityGuidelines.map(({ title, link }) => (
              <Link
                to={link}
                key={link}
                // onClick={() => scrollToTop(link)}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "26px",
                    color: COLORS.grey500,
                  }}
                >
                  {title}
                </Typography>
              </Link>
            ))}
          </Box>
        )} */}
      </div>
    </Wrapper>
  );
}

export default React.memo(NavigateChat);
